/*
* @Author: Jeff Shields
* @Date:   2019-03-26 15:34:11
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2019-08-14 14:08:18
*/
.footer {
    background-color: #000;
    background-image:  url("/cmsb/uploads/esplanade-1920x600.jpg");
    color: #fff;

    a {
        color: #fff;
    }
}

.scrolled{
    background-color: #000 !important;
}

.nav-logo{
    height: 2.6em;
    margin-left: 1em;

    @include media-breakpoint-up(md) {
        height: 3em;
        margin-left: 3em;
    }

    @include media-breakpoint-up(lg) {
        height: 3.5em;
        margin-left: 4em;
    }

}

.mbr-section-btn a.btn:not(.btn-form) {
    border-radius: 100px;
}
.mobi .btn {
    padding: 0.4rem 1rem;
    display: -webkit-inline-flex;
    align-items: center;
    -webkit-align-items: center;
}

.btn-white-outline{
    background: none;
    border-color: #ffffff;
    color: #fff !important;
}

.btn-white-outline:active,
.btn-white-outline.active {
    background: #fff;
    border-color: #ffffff;
    color: #000 !important;
}

.loading-rn{
    display: none;
}
.google-map {
    height: 25rem;
    position: relative;
}

.google-map iframe {
    height: 100%;
    width: 100%;
    // min-height: 300px;
}

#embeded iframe,
.embeded iframe  {
    width: 100%;
}

.navbar-dark .navbar-nav .nav-link{
    color: #fff;
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
    color: #ccc;
}

.overlay {
    background-color: #000;
    opacity: 1;
    // pointer-events: none;
}
// .overlay {
//     display: block !important;
//     opacity: 0;
// }

.overlay:hover {
    background-color: #efefef;
    opacity: .5;
    transition: opacity .3s;
}

.page-title{
    display:none;
}
.media-container-row {
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
}
.flipper .card_cont {
  -o-transition: all .5s;
  -ms-transition: all .5s;
  -moz-transition: all .5s;
  -webkit-transition: all .5s;
  transition: all .5s;
  -o-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
  top: 0px;
  left: 0px;
  text-align: center;
  background-color: #eee;
}
.flipper .card-front {
  z-index: 2;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.flipper .card-front img {
  min-height: 100%;
  min-width: 100%;
  width: auto;
  max-width: 600px;
}
.flipper .card_back {
  width: 100%;
  height: 100%;
  padding: 30px 30px;
  color: #ffffff;
  background-color: #55b4d4;
  z-index: 1;
  margin: 0;
  text-align: center;
  overflow: hidden;
  -o-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}
.flipper .card {
  min-height: 300px;
}
.flipper .card:hover .card-front {
  z-index: 1;
  -o-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.flipper .card:hover .card_back {
  z-index: 2;
  -o-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
}

/*
* @Author: Jeff Shields
* @Date:   2019-03-26 12:52:48
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2019-08-14 13:48:05
*/

$dark: #000;
$secondary: #EFEFEF;
$body-bg:#F9F9F9;

$navbar-dark-color: #fff;
// $theme-colors: map-merge(
//   (
//     "primary":    $primary,
//     "secondary":  $secondary,
//     "success":    $success,
//     "info":       $info,
//     "warning":    $warning,
//     "danger":     $danger,
//     "light":      $light,
//     "dark":       $dark
//   ),
//   $theme-colors
// );

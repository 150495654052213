/* !main
* @Author: Jeff Shields
* @Date:   2019-01-14 08:53:09
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2019-08-14 13:39:18
*/

// ===========================================================
@import "project/setup";


// ===========================================================
//                    Load themes
// ===========================================================
// @import "bs4themes/bootswatch/dist/xxxxx/variables";

// Required
@import "project/bs4-required";

// Your variable overrides go here, e.g.:
@import "mythemes/kathymajor/variables";

@import "project/load-bs4";
@import "mythemes/kathymajor/custom";

// @import "bs4themes/bootswatch/dist/xxxxx/bootswatch";

// @import "mythemes/kathymajor/mbr-additional";
